import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { ButtonExtLink } from 'components/Shared/_Links'

import ShopNav from 'components/member/ShopNav'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.section`
  width: 100%;
  min-height: calc(100vh - var(--header-height) - 100px);
  --column-count: 2;
  ${p => p.theme.mq.md`
    --column-count: 3;
  `}
  --column-gap: 2vw;
  column-count: var(--column-count);
  column-gap: var(--column-gap);

  div {
    border: 0.05rem solid var(--color-gray-medium);
    margin-bottom: var(--column-gap);
    padding: var(--margin-default) 0;
    break-inside: avoid;
    background-image: var(--gradient-btn);

    h3 {
      margin-bottom: 2rem;
    }
    a {
      font-size: calc(var(--font-size-default) * 0.95);
    }
  }
`
//#endregion

const menu = [
  {
    title: '現有買賣',
    href:
      'https://drive.google.com/drive/folders/1o9fTtfgHFT-Ono14AUPbsDaowkckve-K',
    label: '二手團服',
  },
  {
    title: '我想買',
    href: 'https://goo.gl/forms/OEBmMz7YbAOehBR32',
    label: '買家資訊',
  },
  {
    title: '我想賣',
    href: 'https://goo.gl/forms/D28OzZTBc0WiwM6G3',
    label: '賣家資訊',
  },
]

const SecondHand = ({ data, location }) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.secondHand
  return (
    <PageContent>
      <Seo title={title} description={description} />
      <ShopNav location={location} />
      <Title>二手團服買賣平台</Title>
      <Wrapper>
        {menu.map((item, i) => {
          return (
            <div key={`menu-${i}`}>
              <h3>{item.title}</h3>
              <ButtonExtLink href={item.href} target='_blank'>
                {item.label}
              </ButtonExtLink>
            </div>
          )
        })}
      </Wrapper>
    </PageContent>
  )
}

export default SecondHand

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        secondHand {
          title
          description
        }
      }
    }
  }
`
