import React from 'react'
import { shopLinks } from 'constant/links'

import SubLinks from 'components/Shared/SubLinks'

const ShopNav = ({ location }) => (
  <SubLinks links={shopLinks} location={location} />
)

export default ShopNav
